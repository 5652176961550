@tailwind base;
@tailwind components;
html {
    @apply min-w-full overflow-x-hidden !important;
}
body {
    font-smooth: always;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    @apply bg-common-white font-theme text-base font-normal leading-normal text-interface-900 lg:overflow-x-hidden ipad:text-base;
}
.container {
    @apply mx-auto max-w-[1130px] overflow-hidden lg:max-w-[90%];
}
#shadow-host-companion {
    @apply hidden;
}
section {
    @apply relative py-[100px] md:py-[70px] ipad:py-16 mobile:py-10;
}
section.secondary-section {
    @apply bg-gradient-to-b from-interface-200 via-interface-200 to-secondary-100;
}
h1 {
    @apply text-5xl font-bold leading-normal ipad:text-[55px] mobile:text-3xl mobile:leading-10;
}
h2 {
    @apply mb-6 text-5xl font-bold ipad:mb-3 ipad:text-4xl mobile:text-3xl;
}
h3 {
    @apply mb-4 text-[32px] font-medium leading-8 ipad:text-2xl ipad:leading-7;
}
a {
    @apply text-primary-500;
}
/* Header */
header {
    @apply absolute top-0 z-20 h-[80px] w-full bg-common-white transition-all duration-100 mobile:relative mobile:h-auto mobile:pb-3;
}
header.sticky-header {
    @apply fixed bg-common-white shadow mobile:relative;
}
header .container {
    @apply flex h-full w-full  items-center lg:max-w-[90%];
}
.reverse-logo {
    @apply hidden;
}
header.sticky-header .primary-logo {
    @apply hidden;
}
header.sticky-header .reverse-logo {
    @apply block;
}
.inner {
    @apply flex items-center mobile:mt-3;
}
.header-nav {
    @apply flex w-full items-center justify-end ipad:hidden;
}

.header-nav.menu-active {
    @apply animate-[moveInAnimation_0.2s_ease-in];
}

@keyframes moveInAnimation {
    0% {
        top: 46px;
        opacity: 0;
    }
    100% {
        top: 64px;
        opacity: 1;
    }
}

/* Navigation */
.nav-wrapper {
    @apply ml-auto w-auto px-5  ipad:mr-[0] mobile:mx-auto mobile:mt-3 mobile:w-full mobile:px-0;
}
.navigation {
    @apply flex items-center justify-center text-common-white;
}
.navigation li a {
    @apply mx-1 cursor-pointer rounded-md px-4 py-2 text-base text-interface-900 hover:text-orange ipad:px-2 mobile:mx-1 mobile:px-0 mobile:text-sm;
}

.navigation li a.active {
    @apply text-orange hover:text-orange;
}

/* 
    mobile navigation
*/
.right-column .mob-nav-menu {
    @apply absolute left-0 top-[64px] bg-common-white ipad:!block;
}
.right-column .mob-nav-menu .navigation {
    @apply ipad:flex-col;
}
.right-column .mob-nav-menu .navigation li {
    @apply ipad:my-1 ipad:flex-col;
}
.right-column .mob-nav-menu .contact-btn {
    @apply ipad:mx-auto ipad:my-4;
}

/*
    Fixed header
*/
.header.fixed-header .navigation li a {
    @apply text-interface-950 hover:text-orange;
}
.header.fixed-header .navigation li a.active {
    @apply text-orange;
}
.header.fixed-header {
    @apply top-[-100px] opacity-0  shadow transition-all duration-100;
}
.header.fixed-header.sticky-header {
    @apply fixed top-0 bg-common-white opacity-100 mobile:relative;
}
.header.fixed-header.sticky-header .inner {
    @apply mobile:mt-0 mobile:pt-1;
}
.btn {
    @apply flex h-[42px] w-full items-center justify-center rounded-lg bg-interface-900 px-6 pb-1 text-base font-bold text-common-white transition-all duration-500;
}
.btn.lg {
    @apply h-[52px] mobile:h-11;
}
.btn.secondary {
    @apply bg-common-white text-interface-900;
}
/* Banner */
.hero-section {
    @apply h-screen min-h-[700px] bg-interface-900 bg-cover bg-no-repeat py-0 text-common-white ipad:h-auto ipad:max-h-none mobile:max-h-[450px] mobile:min-h-0 mobile:pb-10;
}
.hero-section .lines {
    background-size: auto 100%;
    @apply absolute right-0 top-0 h-full w-full bg-right bg-no-repeat opacity-70 ipad:opacity-40;
}
.hero-section .content {
    @apply absolute left-0 right-0 top-0 flex h-full w-full items-center justify-center mobile:relative;
}
.hero-section .content h1 {
    @apply mb-7 leading-[68px] text-common-white ipad:mb-3 mobile:leading-[46px];
}
.hero-section .content h1 span {
    @apply mt-2 inline-block bg-primary-500  pb-3 pl-[10px] pr-[10px] leading-[44px] text-interface-900;
}
.hero-section .content p {
    @apply mb-8 text-xl font-normal ipad:text-xl mobile:text-base;
}
.hero-section .hero-illustration {
    @apply absolute right-0 top-0;
}
/* .industries-list {
    @apply relative -mb-11;
}
.industries-list .item {
    @apply flex gap-16 mb-12 text-interface-700 even:flex-row-reverse last-of-type:mb-0 ipad:gap-6 mobile:flex-col-reverse mobile:even:flex-col-reverse;
}
.industries-list .item .content {
    @apply flex items-center;
}
.industries-list .item .image {
    @apply w-full max-w-[523px] flex-shrink-0 overflow-hidden rounded-br-[32px] rounded-tl-[32px] mobile:w-full mobile:max-w-full;
}
.industries-list .item .image img {
    @apply mobile:max-w-full;
} */
.features li {
    @apply relative mb-1 ml-3 pl-6 text-interface-700 ipad:ml-0 mobile:pl-4;
}
.features li:before {
    content: '';
    @apply absolute left-0 top-[10px] h-1 w-1 rounded-full bg-interface-700 mobile:top-2;
}
.form-group {
    @apply mb-4;
}
.form-group label {
    @apply mb-[10px] block text-base font-semibold text-interface-900;
}
.form-control {
    border: 1px solid #f7e8d8;
    @apply h-12 w-full resize-none rounded-md bg-common-white px-4 py-[14px] text-interface-900 shadow-sm outline-none placeholder:text-interface-700 focus:border-primary-500;
}
.form-control.error,
.form-control.invalid {
    @apply border-[#dc2626] focus:border-[#dc2626];
}
textarea.form-control {
    @apply h-[112px] pt-3;
}
.masonry-gallery li:nth-child(1) {
    @apply float-left w-[33%] pb-[30.5%] pr-[1.4%];
}
.masonry-gallery li:nth-child(2) {
    @apply float-left w-[67%] pb-[3%] pl-[1.4%];
}
.masonry-gallery li:nth-child(3) {
    @apply float-left w-[34.5%] pb-[3%] pl-[1.4%] pr-[1.4%];
}
.masonry-gallery li:nth-child(4) {
    @apply float-left w-[32.5%] pb-[3%] pl-[1.4%];
}
.masonry-gallery li:nth-child(5) {
    @apply float-left mt-[-28.8%] w-[66.3%] bg-[#D1DFDF];
}
.masonry-gallery li:nth-child(6) {
    @apply float-left w-[33.5%] pl-[3%];
}
.masonry-gallery li:nth-child(5) img {
    @apply w-[96.5%];
}
.masonry-gallery li img {
    @apply w-full object-cover;
}
/* ============
    Animate css
============= */
@media screen and (min-width: 1100px) {
    :root {
        --animate-duration: 1s;
        --animate-delay: 1s;
        --animate-repeat: 1;
    }
    .animate__animated {
        -webkit-animation-duration: 1s;
        animation-duration: 1s;
        -webkit-animation-duration: var(--animate-duration);
        animation-duration: var(--animate-duration);
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
    }
    .animate__animated.animate__infinite {
        -webkit-animation-iteration-count: infinite;
        animation-iteration-count: infinite;
    }
    .animate__animated.animate__repeat-1 {
        -webkit-animation-iteration-count: 1;
        animation-iteration-count: 1;
        -webkit-animation-iteration-count: var(--animate-repeat);
        animation-iteration-count: var(--animate-repeat);
    }
    .animate__animated.animate__repeat-2 {
        -webkit-animation-iteration-count: calc(1 * 2);
        animation-iteration-count: calc(1 * 2);
        -webkit-animation-iteration-count: calc(var(--animate-repeat) * 2);
        animation-iteration-count: calc(var(--animate-repeat) * 2);
    }
    .animate__animated.animate__repeat-3 {
        -webkit-animation-iteration-count: calc(1 * 3);
        animation-iteration-count: calc(1 * 3);
        -webkit-animation-iteration-count: calc(var(--animate-repeat) * 3);
        animation-iteration-count: calc(var(--animate-repeat) * 3);
    }
    .animate__animated.animate__delay-1s {
        -webkit-animation-delay: 0.5s;
        animation-delay: 0.5s;
        -webkit-animation-delay: var(--animate-delay);
        animation-delay: var(--animate-delay);
    }
    .animate__animated.animate__delay-2s {
        -webkit-animation-delay: calc(1s * 1);
        animation-delay: calc(1s * 1);
        -webkit-animation-delay: calc(var(--animate-delay) * 1);
        animation-delay: calc(var(--animate-delay) * 1);
    }
    .animate__animated.animate__delay-3s {
        -webkit-animation-delay: calc(1s * 1.5);
        animation-delay: calc(1s * 1.5);
        -webkit-animation-delay: calc(var(--animate-delay) * 1.5);
        animation-delay: calc(var(--animate-delay) * 1.5);
    }
    .animate__animated.animate__delay-4s {
        -webkit-animation-delay: calc(1s * 2);
        animation-delay: calc(1s * 2);
        -webkit-animation-delay: calc(var(--animate-delay) * 2);
        animation-delay: calc(var(--animate-delay) * 2);
    }
    .animate__animated.animate__delay-5s {
        -webkit-animation-delay: calc(1s * 2.5);
        animation-delay: calc(1s * 2.5);
        -webkit-animation-delay: calc(var(--animate-delay) * 2.5);
        animation-delay: calc(var(--animate-delay) * 2.5);
    }
    @-webkit-keyframes slideInLeft {
        from {
            -webkit-transform: translate3d(-200%, 0, 0);
            transform: translate3d(-200%, 0, 0);
            visibility: visible;
        }

        to {
            -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
        }
    }
    @keyframes slideInLeft {
        from {
            -webkit-transform: translate3d(-200%, 0, 0);
            transform: translate3d(-200%, 0, 0);
            visibility: visible;
        }

        to {
            -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
        }
    }
    @media only screen and (min-width: 900px) {
        .section.active .animate__slideInLeft {
            -webkit-animation-name: slideInLeft;
            animation-name: slideInLeft;
            @apply opacity-100;
        }
    }

    @-webkit-keyframes slideInRight {
        from {
            -webkit-transform: translate3d(200%, 0, 0);
            transform: translate3d(200%, 0, 0);
            visibility: visible;
        }

        to {
            -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
        }
    }
    @keyframes slideInRight {
        from {
            -webkit-transform: translate3d(200%, 0, 0);
            transform: translate3d(200%, 0, 0);
            visibility: visible;
        }

        to {
            -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
        }
    }
    .section.active .animate__slideInRight {
        -webkit-animation-name: slideInRight;
        animation-name: slideInRight;
        @apply opacity-100;
    }

    @-webkit-keyframes slideInUp {
        from {
            -webkit-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100%, 0);
            visibility: visible;
        }

        to {
            -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
        }
    }
    @keyframes slideInUp {
        from {
            -webkit-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100%, 0);
            visibility: visible;
        }

        to {
            -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
        }
    }
    .section.active .animate__slideInUp {
        -webkit-animation-name: slideInUp;
        animation-name: slideInUp;
        @apply opacity-100;
    }

    /* Sliding entrances */
    @-webkit-keyframes slideInDown {
        from {
            -webkit-transform: translate3d(0, -200%, 0);
            transform: translate3d(0, -200%, 0);
            visibility: visible;
        }

        to {
            -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
        }
    }
    @keyframes slideInDown {
        from {
            -webkit-transform: translate3d(0, -200%, 0);
            transform: translate3d(0, -200%, 0);
            visibility: visible;
        }

        to {
            -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
        }
    }
    .section.active .animate__slideInDown {
        -webkit-animation-name: slideInDown;
        animation-name: slideInDown;
        @apply opacity-100;
    }

    @-webkit-keyframes fadeInDown {
        from {
            opacity: 0;
            -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0);
        }

        to {
            opacity: 1;
            -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
        }
    }
    @keyframes fadeInDown {
        from {
            opacity: 0;
            -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0);
        }

        to {
            opacity: 1;
            -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
        }
    }
    .section.active .animate__fadeInDown {
        -webkit-animation-name: fadeInDown;
        animation-name: fadeInDown;
        @apply opacity-100;
    }
    @-webkit-keyframes fadeIn {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }
    @keyframes fadeIn {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }
    .section.active .animate__fadeIn {
        -webkit-animation-name: fadeIn;
        animation-name: fadeIn;
    }
}
.loader,
.loader:after {
    border-radius: 50%;
    width: 10em;
    height: 10em;
}
.loader {
    margin: 0px auto;
    font-size: 3px;
    position: relative;
    text-indent: -9999em;
    border-top: 1.1em solid rgba(255, 255, 255, 0.2);
    border-right: 1.1em solid rgba(255, 255, 255, 0.2);
    border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
    border-left: 1.1em solid #ffffff;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 1.1s infinite linear;
    animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@tailwind utilities;
